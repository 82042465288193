import { TestBoardStateCode, TestChannelStateCode } from '@/includes/types/Board.types'
import boardStateInfo from '@/components/HelpMessages/TestStatusHelpInfo/Board'
import channelStateInfo from '@/components/HelpMessages/TestStatusHelpInfo/Channel'

import Component from 'vue-class-component'
import Vue from 'vue'
import { has, snakeCase } from 'lodash'
import { Emit } from 'vue-property-decorator'
import { ChannelService } from '@/includes/services/ChannelService'
import { errorNotification } from '@/includes/services/NotificationService'

@Component({
    data() {
        return {
            boardStateInfo,
            channelStateInfo
        }
    }
})
export default class TestStateMixin extends Vue {
    @Emit()
    updateChannelTestState(state: TestChannelStateCode, channelId: number, boardId: string): { state: TestChannelStateCode, channelId: number, boardId: string } {
        return { state, channelId, boardId }
    }

    buttonDisabled = false

    isLoading = false

    isStateModalOpen = false

    state = {
        code: '',
        entity: ''
    }

    async testChannelStatus(boardKey:string, channelId:number): Promise<void> {
        this.buttonDisabled = true

        this.isLoading = true

        try {
            const response = await ChannelService.testChannel('tg', {
                board_key: boardKey,
                channel_id: channelId
            })
            this.buttonDisabled = false
            this.updateChannelTestState(response.status, channelId, boardKey)
        } catch (error: any) {
            if (error.response.status === 429) {
                this.buttonDisabled = true

                setTimeout(() => this.buttonDisabled = false, 15000)
                errorNotification('Попробуйте проверить статус позже')
            } else {
                errorNotification(error)
                this.buttonDisabled = false
            }
        } finally {
            this.isLoading = false
        }
    }

    stateAlias(state: TestBoardStateCode | TestChannelStateCode): string {
        return this.$te(snakeCase(state)) ? this.$t(snakeCase(state)).toString() : state.toString()
    }

    handleOnChannelStateClick(data: { state: TestBoardStateCode | TestChannelStateCode, entity: 'channel' | 'board' }): void {
        this.state.code = data.state
        this.state.entity = data.entity

        if (has(boardStateInfo, `board_test_state_${ snakeCase(data.state) }`)) {
            this.isStateModalOpen = true
        }
        if (has(channelStateInfo, `channel_test_state_${ snakeCase(data.state) }`)) {
            this.isStateModalOpen = true
        }
    }

    statusInfoData(board_id?: string): any {
        if (this.state.entity === 'board') {
            if ([ 'UndefinedError', 'CommunicationError', 'HandlerError' ].includes(this.state.code)) {
                return boardStateInfo[`board_test_state_${ snakeCase(this.state.code) }`](board_id)
            } else {
                return boardStateInfo[`board_test_state_${ snakeCase(this.state.code) }`]
            }
        }
        if (this.state.entity === 'channel') {
            return channelStateInfo[`channel_test_state_${ snakeCase(this.state.code) }`]
        }
    }
}
