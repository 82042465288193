



























































































































const enum ViewState {
  targetInput = 'targetInput',
  targetSelectTopic = 'targetSelectTopic',
  editTarget = 'editTarget'
}

import AddEntityCard from '@/components/AddEntityCard.vue'
import { ForwardAction, ForwardActionTarget, ForwarMessageActionMode } from '@/includes/types/Post.types'
import TestStateMixin from '@/mixins/TestStateMixin'
import { CheckChannelsResponse, FailTarget, FlowsService, SubscriptionTarget } from '@/includes/services/FlowService'
import { errorNotification, successNotification, warningNotification } from '@/includes/services/NotificationService'
import CheckChannelsListItem from '@/components/Post/CheckChannelsList/CheckChannelsListItem.vue'
import { InputSetups } from '@/mixins/input-setups'
import { FrontendTargetData } from '@/components/Post/CheckChannelsList/types'
import { rawTargetsToFrontend } from "@/components/Post/CheckChannelsList/logic";

import ConfigField from 'piramis-base-components/src/components/ConfigField/ConfigField.vue'
import SelectInput from 'piramis-base-components/src/components/Pi/fields/SelectInput/SelectInput.vue'
import TextInput from 'piramis-base-components/src/components/Pi/fields/TextInput/TextInput.vue'
import { IterableItemFactory, IterableListItem } from 'piramis-base-components/src/shared/utils/IterableItemFactory'

import { Component, VModel, Mixins, Watch } from 'vue-property-decorator'
import isURL from 'validator/lib/isURL'
import { cloneDeep } from 'lodash'

@Component({
  components: {
    CheckChannelsListItem,
    AddEntityCard,
    ConfigField,
    SelectInput,
    TextInput
  }
})
export default class CheckChannelsList extends Mixins(TestStateMixin, InputSetups) {
  @VModel() targets!: ForwardAction['targets']

  @Watch('frontendData')
  onChange() {
    this.targets = this.frontendData.map(d => d.value.target as ForwardActionTarget)
  }

  updateInProgress = false

  isTargetModalOpen = false

  get modalTitle() {
    if (this.viewState === ViewState.editTarget) {
      return this.$t('check_channels_list_modal_title_edit_target').toString()
    } else if (this.viewState === ViewState.targetSelectTopic) {
      return this.$t('check_channels_list_modal_title_target_select_topic').toString()
    } else if (this.viewState === ViewState.targetInput) {
      return this.$t('check_channels_list_modal_title_target_input').toString()
    } else {
      return ''
    }
  }

  targetValue = ''

  viewState: ViewState = ViewState.targetInput

  iterableItemFactory = new IterableItemFactory()

  newTarget: IterableListItem<FrontendTargetData> = this.iterableItemFactory.create(this.defaultNewTargetModel())

  defaultNewTargetModel() {
    return {
      target: { mode: ForwarMessageActionMode.Forward, },
      info: null
    }
  }

  errorTarget: { value: string; } & FailTarget | null = null

  frontendData: Array<IterableListItem<FrontendTargetData>> = []

  targetAlreadyExists(targetInfo: string) {
    return this.frontendData.find(d => {
      const { info } = d.value

      return info && (
        info.value === targetInfo
        || info.value.includes(targetInfo)
        || info.status && (
          info.info.login.includes(targetInfo)
          || info.info.title.includes(targetInfo)
          || info.info.id.toString().includes(targetInfo)
          || targetInfo.includes(info.info.title)
          || targetInfo.includes(info.info.login)
          || targetInfo.includes(info.info.id.toString())
        )
      )
    })
  }

  updateTarget() {
    const data = cloneDeep(this.newTarget)
    const index = this.frontendData.findIndex(d => d.guid === data.guid)

    if (data.value.info && data.value.info.status &&  data.value.info.info.type === 'FORUM') {
      const topic = this.getTopicId(data.value.target?.topic?.toString() ?? '')

      data.value.target.topic = topic ?? null
    }

    this.frontendData.splice(index, 1, data)
  }

  retest(target: IterableListItem<FrontendTargetData>) {
    this.updateInProgress = true

    this.checkChannels([ target.value.info!.value.toString() ])
      .then(res => {
        if (res && res.length) {
          const item = cloneDeep(target)
          const index = this.frontendData.findIndex(d => d.guid === item.guid)

          item.value.info = res[0]

          this.frontendData.splice(index, 1, item)
        }
      })
      .finally(() => {
        this.updateInProgress = false
      })
  }

  setToEdit(item: IterableListItem<FrontendTargetData>) {
    this.newTarget = cloneDeep(item)

    this.viewState = ViewState.editTarget
    this.isTargetModalOpen = true
  }

  removeTarget(guid: string) {
    this.frontendData = this.frontendData.filter(d => d.guid !== guid)
  }

  getTopicId(topicValue: string | null) {
    if (topicValue && topicValue.trim().length) {
      const isUrl = isURL(topicValue)

      if (isUrl) {
        const splitted = topicValue.trim().split('/').at(-1)

        if (splitted) {
          return +splitted
        }
      } else {
        const parsed = Number.parseInt(topicValue)

        if (parsed && !Number.isNaN(parsed)) {
          return parsed
        }
      }
    }
  }

  handleSaveButtonClick() {
    if (this.viewState === ViewState.editTarget) {
      this.updateTarget()
    } else {
      this.saveTopic()
    }

    successNotification(this.$t('success').toString())
    this.resetState()
  }

  saveTopic() {
    const data = cloneDeep(this.newTarget.value)
    const id = this.getTopicId(data.target.topic?.toString() ?? null)

    data.target.topic = id ?? null

    this.frontendData.push(this.iterableItemFactory.create(data))
  }

  addTarget() {
    this.errorTarget = null;
    const trimmedTargetValue = this.targetValue.trim()

    if (!this.targetAlreadyExists(trimmedTargetValue) && trimmedTargetValue.length > 0) {
      this.checkChannels([ trimmedTargetValue ])
        .then(res => {
          if (res && res.length) {
            const item = res[0]

            if (item.status) {
              if (item.info.type !== 'FORUM') {
                this.frontendData.push(this.iterableItemFactory.create({
                  target: {
                    mode: this.newTarget.value.target.mode,
                    id: item.info.id
                  },
                  info: item
                }))

                this.resetState()

                successNotification(this.$t('success').toString())
              } else {
                this.newTarget.value.info = item
                this.newTarget.value.target.id = item.info.id
                this.newTarget.value.target.topic = ''

                this.viewState = ViewState.targetSelectTopic
              }
            } else {
              this.errorTarget = item
            }
          }
        })
    } else {
      warningNotification(this.$t('check_channels_list_target_already_exists').toString())
    }
  }

  get queriedItems(): CheckChannelsResponse['data'] {
    const filterExistedTargets = (t: {value: string} & SubscriptionTarget) => !this.targets.some(et => et && et.id === (t && t.status && t.info.id))

    if (this.targetValue) {
      return this.transformedTargetsResponseLike
        .filter(t => t && t.value.toLowerCase().includes(this.targetValue.toLowerCase()))
        .filter(filterExistedTargets)
    }

    return this.transformedTargetsResponseLike.filter(filterExistedTargets)
  }

  get transformedTargetsResponseLike(): CheckChannelsResponse['data'] {
    return this.$store.getters.activeBoardChannels.map((target) => {
      if (target.test_state === 'Success') {
        return {
          value: target.title,
          status: true,
          info: {
            id: target.id,
            login: target?.username ?? '',
            title: target.title,
            type: 'CHANNEL'
          }
        }
      } else {
        return {
          value: target.title,
          status: false,
          error: this.stateAlias(target.test_state)
        }
      }
    })
      .sort((a:any) => {
        if (!a.status) return 1
        return 0
      })
  }

  findTarget(id: string) {
    return this.transformedTargetsResponseLike.find(t => (t && t.value) === id)
  }

  selectOption(value:string):void {
    this.errorTarget = null;

    const target = this.findTarget(value)

    if (target) {
      if (target.status) {
        this.frontendData.push(this.iterableItemFactory.create({
          target: {
            mode: this.newTarget.value.target.mode,
            id: target.info.id
          },
          info: target
        }))

        this.resetState()
      } else {
        this.errorTarget = target
      }
    }
  }

  resetState() {
    this.newTarget = this.iterableItemFactory.create(this.defaultNewTargetModel())

    this.isTargetModalOpen = false
    this.viewState = ViewState.targetInput
    this.errorTarget = null
    this.targetValue = ''
  }

  checkChannels(targets:Array<string> | Array<number>) {
    return FlowsService.checkChannels('tg', {
      board_key: this.$store.state.boardsState.activeBoard!.board,
      values: targets.map(t => t.toString())
    })
      .then(({ data }) =>  data)
      .catch(errorNotification)
  }

  async created() {
    const data = await this.checkChannels(this.targets.map(t => t.id))

    if (data) {
      this.frontendData = rawTargetsToFrontend(this.targets, data)
    }
  }
}

