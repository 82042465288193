import i18n from '@/i18n/i18n'

export default {
    'channel_test_state_api_error': {
        type: 'Text',
        text: i18n.t('board_api_error_help_message')
    },

    'channel_test_state_channel_not_found_error': {
        type: 'Text',
        text: i18n.t('add_bot_to_channel_help_message')
    },
    'channel_test_state_channel_permission_send_message_error': {
        type: 'Text',
        text: i18n.t('channel_test_state_channel_permission_send_message_error')
    },
    'channel_test_state_channel_permission_delete_message_error': {
        type: 'Text',
        text: i18n.t('channel_test_state_channel_permission_delete_message_error')
    },
    'channel_test_state_channel_permission_edit_message_error': {
        type: 'Text',
        text: i18n.t('channel_test_state_channel_permission_edit_message_error')
    },
    'channel_test_state_channel_permission_add_users_error': {
        type: 'Text',
        text: i18n.t('channel_test_state_channel_permission_add_users_error')
    },
    'channel_test_state_connected_group_not_found_error': {
        type: 'Text',
        text: i18n.t('channel_test_state_connected_group_not_found_error')
    },
    'channel_test_state_connected_group_permission_send_message_error': {
        type: 'Text',
        text: i18n.t('channel_test_state_connected_group_permission_send_message_error')
    },
    'channel_test_state_connected_group_permission_pin_message_error': {
        type: 'Text',
        text: i18n.t('channel_test_state_connected_group_permission_pin_message_error')
    },
    'channel_test_state_connected_group_permission_delete_message_error': {
        type: 'Text',
        text: i18n.t('channel_test_state_connected_group_permission_delete_message_error')
    },
    'channel_test_state_connected_group_permission_edit_message_error': {
        type: 'Text',
        text: i18n.t('channel_test_state_connected_group_permission_edit_message_error')
    },
    'channel_test_state_connected_group_bot_not_admin': {
        type: 'Text',
        text: i18n.t('channel_test_state_connected_group_bot_not_admin')
    }
}